import classNames from 'classnames';
import { CustomLink as Link } from '@/components/custom-link';
import { useUser } from '@auth0/nextjs-auth0/client';

import useHandleFavourites from '@/hooks/use-handle-favourites/use-handle-favourites';
import { useFeatureTogglesContext } from '@/context/feature-toggles/feature-toggles-context';
import { FEATURE_TOGGLE_LIST } from '@/context/feature-toggles/feature-toggles.types';
import Carousel from '@/components/data-display/carousel';
import PartnerTile from '@/components/integrated/partner-tile';
import { VARIANT as CAROUSEL_VARIANT } from '@/components/data-display/carousel/carousel.types';
import VersatileCard from '@/components/integrated/versatile-card';
import registerPartnerClick from '@/utils/register-partner-click/register-partner-click';
import { COLLECTION_MECHANIC } from '@/models/collection-mechanic/collection-mechanic.types';
import {
  TILE_TYPE,
  LIST_TYPE,
  PartnerListProperties,
  PartnerListType,
} from './partner-list.types';
import styles from './partner-list.module.scss';

export const appendQueryParameter = (url: string, isCLOList: boolean) => {
  if (!url || !isCLOList) return url;

  const queryParameterClo = isCLOList ? `?cm=${COLLECTION_MECHANIC.CLO}` : '';

  return `${url}${queryParameterClo}`;
};

const PartnerList = ({
  partnerList,
  speedyAwardText,
  className = '',
  listType = LIST_TYPE.col5,
  tileType = TILE_TYPE.Simple,
  clickLocation,
  isCLOList = false,
}: PartnerListProperties) => {
  const { isFavourite, toggleFavourite } = useHandleFavourites();

  const { user } = useUser();
  const featuresToggles = useFeatureTogglesContext();
  const showFavourite =
    user && !featuresToggles.includes(FEATURE_TOGGLE_LIST.NO_FAVS);

  if (!partnerList || partnerList.length === 0) {
    return null;
  }

  return listType === LIST_TYPE.carousel ? (
    <Carousel
      variant={CAROUSEL_VARIANT.MultiPartners}
      className={styles['partner-list-carousel']}
    >
      {partnerList?.map(item => {
        return (
          <Carousel.Item
            className={styles['partner-list-carousel__item']}
            key={item.id}
          >
            <Link
              href={appendQueryParameter(
                item?.content?.partner?.destinationUrl ?? '#',
                isCLOList,
              )}
              className={styles['partner-list-carousel__link']}
              title={item.content?.partner?.name}
              aria-label={item.content?.partner?.name}
            >
              <VersatileCard
                id={item.id}
                favourite={{
                  showFavourite,
                  favouriteId:
                    isFavourite(item.content?.partner?.mechanicId as number) ??
                    undefined,
                  toggleFavourite,
                }}
                image={{
                  ...item.image,
                }}
                content={{
                  ...item.content,
                }}
              />
            </Link>
          </Carousel.Item>
        );
      })}
    </Carousel>
  ) : (
    <div
      role="grid"
      className={classNames(
        styles['partner-list'],
        styles[listType],
        className,
      )}
    >
      {renderPartnerGrid(
        partnerList,
        tileType,
        clickLocation,
        isCLOList,
        speedyAwardText,
      )}
    </div>
  );
};

const renderPartnerGrid = (
  partnerList: PartnerListType,
  tileType: string,
  clickLocation: any,
  isCLOList: boolean,
  speedyAwardText?: string,
) => {
  return partnerList.map(item => {
    const isHero = tileType === TILE_TYPE.Hero;
    const href = appendQueryParameter(
      item?.destinationUrl ?? item?.content?.partner?.destinationUrl,
      isCLOList,
    );

    const regex = /https:\/\//;
    const target = typeof href === 'string' && regex.test(href) ? '_blank' : '';

    return isHero ? (
      <Link
        key={item.id}
        href={href ?? '#'}
        target={target}
        onClick={() => registerPartnerClick(clickLocation, item.id)}
        title={item.content?.partner?.name}
        aria-label={item.content?.partner?.name}
        className={classNames(styles['partner-list__link'])}
      >
        <VersatileCard
          id={item.id}
          image={{
            ...item.image,
            mobileHeight: 100,
          }}
          content={item.content}
          className={styles['partner-list__hero-versatile-card']}
        />
      </Link>
    ) : (
      <Link
        key={item.slug}
        href={href}
        target={target}
        onClick={() => registerPartnerClick(clickLocation, item.slug)}
        title={item.name}
        aria-label={item.name}
        className={classNames(styles['partner-list__link'])}
      >
        <PartnerTile
          name={item.name}
          slug={item.slug}
          logo={{
            src: item.logoSrc,
            alt: item.altLogo || item.name,
          }}
          isSpeedyAwarding={item.isSpeedyAwarding}
          speedyAwardText={item.speedyAwardText ?? speedyAwardText}
          rate={item.rate}
          wasRate={item.wasRate}
          className={styles['partner-list__partner-tile']}
        />
      </Link>
    );
  });
};

export default PartnerList;
